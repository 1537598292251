export const Tag = ({
  children,
  onClick,
}: { children: string; onClick?: () => void }) => {
  return (
    <div
      onClick={onClick}
      onKeyDown={onClick}
      className="inline-flex cursor-pointer flex-row rounded-full bg-white/10 px-3 py-[6px] text-[13px] text-white leading-0"
    >
      {children}
    </div>
  );
};
