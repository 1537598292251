export const BrandazineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      role="img"
      aria-label="Brandazine"
    >
      <g clip-path="url(#clip0_528_7455)">
        <circle cx="8" cy="8" r="8" fill="white" fill-opacity="0.96" />
        <path d="M6 0V12" stroke="#111111" stroke-width="1.5" />
        <circle cx="10" cy="9.5" r="1.75" stroke="#111111" stroke-width="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_528_7455">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
