import { useSuspenseQueries } from '@tanstack/react-query';
import { type ImgHTMLAttributes, Suspense } from 'react';

export const preloadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      resolve(img);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });

export const usePreloadImages = (urls: string[]) => {
  useSuspenseQueries({
    queries: urls.map((url) => ({
      queryKey: ['image', url],
      queryFn: () => preloadImage(url),
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: Number.POSITIVE_INFINITY,
    })),
  });
};

export interface SuspenseImageProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  fallback?: React.ReactNode;
}

const SuspenseImageInternal = ({
  src,
  ...props
}: Omit<SuspenseImageProps, 'fallback'>) => {
  usePreloadImages([src]);

  // biome-ignore lint/a11y/useAltText: <explanation>
  return <img {...props} src={src} />;
};

export const SuspenseImage = ({
  src,
  fallback,
  ...props
}: SuspenseImageProps) => {
  return (
    <Suspense fallback={fallback}>
      <SuspenseImageInternal src={src} {...props} />
    </Suspense>
  );
};
