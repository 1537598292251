import { ampli } from '@/ampli';
import { jotaiStore } from '@/store/jotai';
import {
  accessTokenAtom,
  getUserAccountIdFromCookie,
  signIn,
} from '@/utils/auth';
import * as Sentry from '@sentry/react';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as v from 'valibot';

const authSchema = v.object({
  token: v.string(),
});

export const Route = createFileRoute('/auth/token')({
  component: AuthTokenPage,
  validateSearch: (search) => v.parse(authSchema, search),
  loaderDeps: ({ search }) => {
    return search;
  },
  loader: async ({ deps: { token } }) => {
    const data = await signIn(token);
    const accessToken = data?.data?.authenticateV2.accessToken;

    if (accessToken) {
      const userAccountId = getUserAccountIdFromCookie();
      ampli.identify(userAccountId);
      Sentry.setUser({
        id: userAccountId,
      });
      jotaiStore.set(accessTokenAtom, accessToken);
      return redirect({ to: '/' });
    }
    return data?.data?.authenticateV2.accessToken;
  },
  pendingComponent: () => <div>Loading...</div>,
});

function AuthTokenPage() {
  return <div />;
}
