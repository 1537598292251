import { ampli } from '@/ampli';
import {
  getPrevAmplitudeHistory,
  registerAmplitudeMap,
  setPrevAmplitudeHistory,
} from '@/ampli/event-schema';
import { DebugContainer } from '@/components/debug/debug-container';
import { Toaster } from '@/components/ui/toaster';
import { jotaiStore } from '@/store/jotai';
import { NotFoundPage } from '@/templates/not-found';
import {
  type AuthContext,
  isAuthenticatedAtom,
  useIsStaff,
} from '@/utils/auth';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { QueryClient } from '@tanstack/react-query';
import {
  type MakeRouteMatchUnion,
  Outlet,
  type ParsedLocation,
  ScrollRestoration,
  createRootRouteWithContext,
  redirect,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Fragment } from 'react';
import { safeParse } from 'valibot';

interface RouterContext {
  auth: AuthContext;
  queryClient: QueryClient;
  apolloClient: ApolloClient<NormalizedCacheObject>;
}

const autoTrackAmplitude = async ({
  location,
  matches,
  search,
}: {
  location: ParsedLocation;
  matches: MakeRouteMatchUnion[];
  search: Record<string, unknown>;
}) => {
  const prevHistory = getPrevAmplitudeHistory();

  setPrevAmplitudeHistory([location.pathname, location.searchStr].join(''));

  for (const match of matches.filter((match) => match.routeId !== '__root__')) {
    const amplitudeMap = registerAmplitudeMap.get(match.routeId as string);
    if (amplitudeMap?.schema) {
      const parse = safeParse(amplitudeMap.schema, search);
      if (parse.success) {
        ampli.track({
          event_type: amplitudeMap.eventName,
          event_properties: { ...parse.output, referer: prevHistory },
        });
      }
    }
  }
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => {
    const isStaff = useIsStaff();

    return (
      <Fragment>
        <div id="mobile-container" className="relative">
          <ScrollRestoration />
          <Outlet />

          <Toaster />
        </div>
        {isStaff && <DebugContainer />}
        {import.meta.env.DEV ? <TanStackRouterDevtools /> : null}
      </Fragment>
    );
  },

  beforeLoad: ({ search, context, location, matches }) => {
    // 인증 페이지 제외 모든 페이지에 인증 체크
    if (location.pathname.startsWith('/auth')) {
      return context;
    }
    // @see context.isAuthenticated 사용시 비동기 문제 발생
    const isAuthenticated = jotaiStore.get(isAuthenticatedAtom);

    if (!isAuthenticated) {
      throw redirect({
        to: '/auth/login',
        search: {
          redirect: [location.pathname, location.searchStr]
            .filter(Boolean)
            .join(''),
        },
      });
    }

    void autoTrackAmplitude({ location, matches, search });

    return context;
  },
  notFoundComponent: () => <NotFoundPage />,
});
