import { jotaiStore } from '@/store/jotai';
import { accessTokenAtom, clearAuthState } from '@/utils/auth';
import { DEV, env } from '@/utils/env';
import {
  ApolloClient,
  type FieldMergeFunction,
  type FieldReadFunction,
  InMemoryCache,
  from,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import dayjs from 'dayjs';

const dayjsFieldReadFn: FieldReadFunction = (value: string | null) =>
  value ? dayjs(value) : null;

const batchHttpLink = new BatchHttpLink({
  uri: env.graphqlUrl,
  credentials: 'include',
  batchMax: 5,
  batchInterval: 20,
});

const persistedQueryLink = createPersistedQueryLink(
  // TODO: JSON-based persisted query resolution
  generatePersistedQueryIdsFromManifest({
    loadManifest: () => import('@/gql/persisted-query-manifest.json'),
  }),
).concat(batchHttpLink);

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Number.POSITIVE_INFINITY,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

const authLink = setContext((_, { headers }) => {
  const accessToken = jotaiStore.get(accessTokenAtom);

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const { extensions } of graphQLErrors) {
      if (extensions?.code === 'UNAUTHORIZED') {
        clearAuthState();
      }
    }
  }
});

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, retryLink, persistedQueryLink]),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    },
  },
  connectToDevTools: import.meta.env.DEV,
  cache: new InMemoryCache({
    typePolicies: {
      ClipImageDto: {
        fields: {
          createdAt: dayjsFieldReadFn,
          curatorInfo: {
            merge: true,
          },
        },
      },
      PaginatedClipImageSearchResultDto: {
        keyFields: ['searchQueryId'],
        fields: {
          items: {
            keyArgs: ['query', 'params'],
            merge: ((existing, incoming, { args, readField }) => {
              if (!Array.isArray(existing)) {
                return incoming;
              }

              if (DEV) {
                console.debug('Merge function called:', {
                  existingLength: (existing ?? []).length,
                  incomingLength: incoming.length,
                  args,
                });
              }

              const merged = [...existing];
              for (const item of incoming) {
                const id = readField('id', item);
                if (
                  !merged.some(
                    (existingItem) => readField('id', existingItem) === id,
                  )
                ) {
                  merged.push(item);
                }
              }
              return merged;
            }) as FieldMergeFunction<{ id: string }[]>,
          },
        },
      },
      Query: {
        fields: {
          clipImages: {
            keyArgs: ['query', 'params'],
          },
          clipImagesByCuratorId: {
            keyArgs: ['curatorId'],
          },
        },
      },
    },
  }),
});
