import { Link, useRouter } from '@tanstack/react-router';
import { FiHome } from 'react-icons/fi';
import { MdArrowBackIosNew } from 'react-icons/md';

export const StickyHeader = () => {
  const { history } = useRouter();

  return (
    <header className="fixed z-20 flex w-full max-w-mobile-container items-center justify-between pt-[22px] pr-[20px] pl-3">
      <button
        type="button"
        onClick={() => {
          history.back();
        }}
        className="relative z-20"
      >
        <MdArrowBackIosNew size={24} />
      </button>

      <Link to="/" className="relative z-20">
        <FiHome size={24} />
      </Link>

      <div
        className="absolute top-0 left-0 z-10 h-[120px] w-full"
        style={{
          background:
            'linear-gradient(0deg, rgba(17, 17, 17, 0.00) 0%, rgba(17, 17, 17, 0.24) 30.38%, rgba(17, 17, 17, 0.60) 100%)',
        }}
      />
    </header>
  );
};
