import { useCallback, useEffect, useState } from 'react';

export interface UseKeyboardToggleOptions {
  defaultValue?: boolean;
  keys: string[];
  onToggle?: (value: boolean) => void;
}

export const useKeyboardToggle = ({
  defaultValue = false,
  keys,
  onToggle,
}: UseKeyboardToggleOptions) => {
  const [isActive, setIsActive] = useState(defaultValue);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const keysPressed = keys.every((key) => {
        switch (key) {
          case 'cmd':
            return event.metaKey;
          case 'ctrl':
            return event.ctrlKey;
          case 'shift':
            return event.shiftKey;
          case 'alt':
            return event.altKey;
          default:
            return event.key.toLowerCase() === key.toLowerCase();
        }
      });

      if (keysPressed) {
        event.preventDefault();

        const newValue = !isActive;
        setIsActive(newValue);
        onToggle?.(newValue);
      }
    },
    [isActive, keys, onToggle],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return [isActive, setIsActive] as const;
};
