import { cn } from '@/lib/utils';
import { type ImgHTMLAttributes, useEffect, useState } from 'react';

interface TransitionImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  smallSrc: string;
  largeSrc: string;
  fallback?: React.ReactNode;
}

export const TransitionImage = ({
  smallSrc,
  largeSrc,
  className,
  fallback,
  ...props
}: TransitionImageProps) => {
  const [isLargeImageLoaded, setIsLargeImageLoaded] = useState(false);
  const [hasLargeImageError, setHasLargeImageError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = largeSrc;

    img.onload = () => {
      setIsLargeImageLoaded(true);
      setHasLargeImageError(false);
    };

    img.onerror = () => {
      setIsLargeImageLoaded(false);
      setHasLargeImageError(true);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [largeSrc]);

  if (!smallSrc && !largeSrc) {
    return fallback;
  }

  return (
    <div className="relative">
      <div className="bg-black">
        <img
          {...props}
          alt=""
          src={smallSrc}
          className={cn(
            className,
            'transition-opacity duration-500',
            isLargeImageLoaded && !hasLargeImageError
              ? 'opacity-0'
              : 'opacity-100',
          )}
          style={{
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
        />

        {isLargeImageLoaded && !hasLargeImageError && (
          <img
            {...props}
            alt=""
            src={largeSrc}
            className={cn(
              className,
              'absolute inset-0',
              'transition-opacity duration-500',
            )}
            style={{
              backfaceVisibility: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
            }}
          />
        )}
      </div>
    </div>
  );
};
