import Brandazine from '@/assets/brandazine.svg?react';
import indexBottomAnimationData from '@/assets/lotties/index-bottom.json';
import RightArrow from '@/assets/miscs/list-right-arrow.svg?react';
import { Input } from '@/components/ui/input';
import { graphql } from '@/gql';
import { Await, Link, createFileRoute, defer } from '@tanstack/react-router';
import Lottie from 'lottie-react';
import { MdSearch } from 'react-icons/md';

export const Route = createFileRoute('/')({
  component: MainPage,
  loader: ({ context }) => {
    const deferredQuery = context.apolloClient.query({
      query: graphql(/* GraphQL */ `
        query HomePageQuery {
          clipSuggestedSearchKeywords
        }
      `),
    });

    return {
      deferredQuery: defer(deferredQuery),
    };
  },
});

function MainPage() {
  const { deferredQuery } = Route.useLoaderData();
  return (
    <main className="h-svh w-full overflow-hidden [@media_screen_and_(max-height:_740px)]:overflow-auto">
      <div className="relative z-10 flex h-full flex-col items-center justify-center px-4 md:justify-start md:pt-[120px]">
        <h1 className="font-title font-medium text-[64px] md:text-[80px] leading-[76px] md:leading-[88px] text-[rgba(255,255,255,0.96)] self-start z-10">
          Creator
          <br />
          Clip
        </h1>
        <Link
          to="/search-preview"
          className="relative z-10 mt-[70px] w-full md:mt-[100px]"
          preload="viewport"
          preloadDelay={1000}
        >
          <MdSearch
            className="absolute top-0 left-0 mx-3 h-10 w-4 text-muted-foreground"
            color="#fff"
          />
          <Input
            variant="outline"
            className="pointer-events-none bg-white/10 pl-9 outline-none"
          />
        </Link>
        <Await promise={deferredQuery}>
          {({ data }) => (
            <ul className="z-20 mt-6 flex w-full flex-col [&>*:nth-child(n+5)]:hidden [&>*:nth-child(4)]:border-b-0 md:[&>*:nth-child(n+5)]:flex md:[&>*:nth-child(4)]:border-b">
              {data?.clipSuggestedSearchKeywords.slice(0, 7).map((keyword) => (
                <Link
                  key={keyword}
                  to="/search"
                  search={{ q: keyword }}
                  className="flex items-center px-1 py-4 cursor-pointer [&:not(:last-child)]:border-b [&:not(:last-child)]:border-white/20"
                >
                  <span className="text-[14px] font-normal leading-[20px] text-white break-keep pr-6">
                    {keyword}
                  </span>
                  <RightArrow className="ml-auto w-7" />
                </Link>
              ))}
            </ul>
          )}
        </Await>
        <Brandazine className="absolute left-4 bottom-7 z-10 w-auto" />

        <Lottie
          animationData={indexBottomAnimationData}
          loop={true}
          draggable={false}
          className="pointer-events-none absolute bottom-0 z-0 w-full"
        />
      </div>
    </main>
  );
}
