import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { cn } from '@/lib/utils';
import { Link, useElementScrollRestoration } from '@tanstack/react-router';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  type HTMLAttributes,
  Suspense,
  forwardRef,
  useMemo,
  useRef,
} from 'react';

import { graphql } from '@/gql';
import { useInfiniteScroll } from '@/hooks/use-infinite-scroll';
import { useKeyboardToggle } from '@/hooks/use-keyboard-toggle';
import { usePreloadImages } from '@/hooks/use-preload-images';
import { useIsStaff } from '@/utils/auth';
import { dynamicChunk } from '@/utils/dynamicChunk';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { type FragmentOf, readFragment } from 'gql.tada';
import { InlineMath } from 'react-katex';

export const GridClipImageContainerFallback = () => {
  return (
    <div className={'grid__dynamic-gallery--set-1 gap-[1px]'}>
      {Array.from({ length: 12 }, (_, index) => (
        <div
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={index}
          className="aspect-square animate-pulse bg-white/10"
          style={{
            gridArea: `i${index + 1}`,
          }}
        />
      ))}
    </div>
  );
};

const clipImageFragment = graphql(/* GraphQL */ `
  fragment ClipImageFragment on ClipImageDto {
    id
    smallImageUrl
    originalImageUrl
    distance
    combinedScore
    curatorInfo {
      instagramFollowerCount
    }
    createdAt
    rankingFactors {
      values {
        followerFactor
        timeDecay
      }
    }
  }
`);

export interface GridClipImageContainerProps {
  searchQueryId: string;
  $data: FragmentOf<typeof clipImageFragment>[];
  className?: string;
  chunkSize?: number;
  onEndReached: () => void;
  fetching: boolean;
  hasMore?: boolean;
}

interface GridClipImageContainerInternalProps
  extends HTMLAttributes<HTMLDivElement> {
  isChunkDataFullyLoaded: boolean;
  data: {
    id: string;
    smallImageUrl: string;
    distance: number | null;
    combinedScore: number | null;
    createdAt: Dayjs | null;
    curatorInfo: {
      instagramFollowerCount: number | null;
    } | null;
    rankingFactors: {
      values: {
        followerFactor: number;
        timeDecay: number;
      };
    } | null;
  }[];
  virtualItemsLength: number;
  index: number;
  searchQueryId: string;
  shouldShowDebugInfo?: boolean;
}

const GridClipImageContainerInternal = forwardRef<
  HTMLDivElement,
  GridClipImageContainerInternalProps
>(
  (
    {
      data,
      isChunkDataFullyLoaded,
      virtualItemsLength,
      index,
      searchQueryId,
      shouldShowDebugInfo,
      ...props
    },
    ref,
  ) => {
    const isStaff = useIsStaff();

    const imageDimensionsRef = useRef<
      Record<string, { width: number; height: number }>
    >({});

    const handleImageLoad = (
      id: string,
      event: React.SyntheticEvent<HTMLImageElement>,
    ) => {
      const img = event.target as HTMLImageElement;
      imageDimensionsRef.current[id] = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };
    };

    usePreloadImages(data.map((item) => item.smallImageUrl));

    return (
      <div ref={ref} {...props}>
        {data.map((item, itemIndex) => (
          <Link
            key={itemIndex}
            to={`/images/${item.id}`}
            search={{ searchQueryId }}
            className={cn(
              'relative aspect-square group',
              isStaff &&
                'before:absolute before:bottom-0 before:p-1 before:text-gray-100 before:text-xs before:drop-shadow-[0_0_4px_#000] before:content-[attr(data-score)]',
            )}
            {...(isStaff && {
              'data-score': item.combinedScore?.toFixed(4),
            })}
            style={{
              gridArea: `i${itemIndex + 1}`,
            }}
          >
            <img
              src={item.smallImageUrl}
              alt=""
              className="h-full w-full object-cover"
              onLoad={(e) => handleImageLoad(item.id, e)}
            />
            {shouldShowDebugInfo && (
              <div className="absolute inset-0 bg-black/80 backdrop-blur-sm opacity-80 m-2">
                <div className="grid gap-1.5 text-white text-2xs p-2">
                  {item.rankingFactors?.values && (
                    <>
                      <div className="space-y-0.5 border-b border-white/20 pb-1.5">
                        <div className="flex items-center justify-between">
                          <span className="text-gray-300">Distance:</span>
                          <span className="font-mono">
                            {item.distance?.toFixed(6)}
                          </span>
                        </div>
                        <div className="bg-white/10 rounded px-1.5 py-1">
                          <InlineMath math={`d = ${item.distance}`} />
                        </div>
                      </div>

                      <div className="space-y-0.5 border-b border-white/20 pb-1.5">
                        <div className="flex items-center justify-between">
                          <span className="text-gray-300">Followers:</span>
                          <span className="font-mono">
                            {(
                              item.curatorInfo?.instagramFollowerCount ?? 0
                            ).toLocaleString()}
                          </span>
                        </div>
                        <div className="bg-white/10 rounded px-1.5 py-1">
                          <InlineMath
                            math={`f = \\max(0.001, 1 - e^{-w_f \\cdot ${((item.curatorInfo?.instagramFollowerCount ?? 0) / 1000).toFixed(1)}K}) = ${item.rankingFactors.values.followerFactor.toFixed(4)}`}
                          />
                        </div>
                      </div>

                      <div className="space-y-0.5 border-b border-white/20 pb-1.5">
                        <div className="flex items-center justify-between">
                          <span className="text-gray-300">Age:</span>
                          <span className="font-mono">
                            {dayjs().diff(item.createdAt, 'days')}d
                          </span>
                        </div>
                        <div className="bg-white/10 rounded px-1.5 py-1">
                          <InlineMath
                            math={`t = \\max(t_{min}, e^{-w_t \\cdot (\\frac{age}{2550})^2}) = ${item.rankingFactors.values.timeDecay.toFixed(4)}`}
                          />
                        </div>
                      </div>

                      {item.distance !== null &&
                        item.combinedScore !== null && (
                          <div className="space-y-0.5 pt-0.5">
                            <div className="bg-white/10 rounded px-1.5 py-1">
                              <InlineMath
                                math={`(1 - \\frac{d}{2}) \\times f \\times t = ${item.combinedScore.toFixed(3)}`}
                              />
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            )}
          </Link>
        ))}
      </div>
    );
  },
);

// @see https://www.figma.com/design/ajIq5pw2ZA8HEfj2nJ0Zm2/CLIP?node-id=561-8116&m=dev
// chunk별 아이템 개수
const CHUNK_TEMPLATE = [12, 12, 9, 12, 12, 9];

export const GridClipImageContainer = ({
  searchQueryId,
  $data,
  className,
  chunkSize = 12,
  onEndReached,
  fetching,
  hasMore,
}: GridClipImageContainerProps) => {
  const data = readFragment(clipImageFragment, $data);
  usePreloadImages(data.map((item) => item.smallImageUrl));

  const parentRef = useRef<HTMLDivElement>(null);
  const sentinelRef = useRef<HTMLDivElement>(null);

  const isStaff = useIsStaff();
  const [showDebugInfo] = useKeyboardToggle({
    defaultValue: false,
    keys: ['ctrl', 'shift', 'v'],
  });

  const chunkedData = useMemo(
    () =>
      dynamicChunk(
        data,
        (index) => CHUNK_TEMPLATE[index % CHUNK_TEMPLATE.length],
      ),
    [data],
  );

  const scrollRestrotationId = `grid-clip-image-container:${searchQueryId}`;

  const scrollEntry = useElementScrollRestoration({
    id: scrollRestrotationId,
  });

  const rowVirtualizer = useVirtualizer({
    count: chunkedData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 1175, // width 720px 기준, 12개의 리스트 높이
    gap: 1,
    initialOffset: scrollEntry?.scrollY,
  });

  useInfiniteScroll({
    target: sentinelRef.current,
    onEndReached,
    disabled: fetching || !hasMore,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();
  const shouldShowDebugInfo = isStaff && showDebugInfo;

  return (
    <div
      ref={parentRef}
      className={cn('scrollbar-hide h-full overflow-auto', className)}
      data-scroll-restoration-id={scrollRestrotationId}
    >
      <div
        className="relative"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {virtualItems.map((virtualRow, index) => {
          const rowIndex = virtualRow.index;
          const rowChunk = chunkedData[rowIndex];
          const gridTemplateSet = (rowIndex % CHUNK_TEMPLATE.length) + 1;
          const isChunkDataFullyLoaded = chunkSize === rowChunk.length;

          return (
            <Suspense key={virtualRow.key}>
              <GridClipImageContainerInternal
                ref={rowVirtualizer.measureElement}
                data-index={virtualRow.index}
                className={cn(
                  'absolute w-full gap-[1px]',
                  `grid__dynamic-gallery--set-${gridTemplateSet}`,
                )}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                }}
                data={rowChunk}
                isChunkDataFullyLoaded={isChunkDataFullyLoaded}
                virtualItemsLength={virtualItems.length}
                index={index}
                searchQueryId={searchQueryId}
                shouldShowDebugInfo={shouldShowDebugInfo}
              />
            </Suspense>
          );
        })}
      </div>

      {/* Sentinel Element */}
      <div
        ref={sentinelRef}
        className={cn(
          'h-20 flex items-center justify-center',
          !hasMore && 'hidden',
        )}
      >
        {fetching && (
          <AiOutlineLoading3Quarters size={24} className="animate-spin" />
        )}
      </div>
    </div>
  );
};

GridClipImageContainer.fragments = clipImageFragment;
