import { useEffect, useRef } from 'react';

interface UseInfiniteScrollProps {
  target: Element | null;
  onEndReached: () => void;
  threshold?: number;
  disabled?: boolean;
}

export const useInfiniteScroll = ({
  target,
  onEndReached,
  threshold = 0,
  disabled = false,
}: UseInfiniteScrollProps) => {
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (disabled || !target) {
      return;
    }

    const currentTarget = target;

    observer.current = new IntersectionObserver(
      async (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          onEndReached();
        }
      },
      { threshold },
    );

    observer.current.observe(currentTarget);

    return () => {
      if (observer.current && currentTarget) {
        observer.current.unobserve(currentTarget);
        observer.current.disconnect();
      }
    };
  }, [target, onEndReached, threshold, disabled]);
};
