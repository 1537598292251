import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Cookies from 'js-cookie';
import { useCallback } from 'react';

export interface DevValues {
  followerWeight?: number;
  timeWeight?: number;
  timeDecayMin?: number;
}

const COOKIE_PREFIX = '__internal_';
const DEV_VALUES_KEY = 'DEV_VALUES';

const devValuesAtom = atomWithStorage<DevValues>(
  DEV_VALUES_KEY,
  {
    followerWeight: undefined,
    timeWeight: undefined,
    timeDecayMin: undefined,
  },
  {
    getItem: (key) => {
      const value = Cookies.get(`${COOKIE_PREFIX}${key}`);
      return value
        ? JSON.parse(value)
        : {
            followerWeight: undefined,
            timeWeight: undefined,
            timeDecayMin: undefined,
          };
    },
    setItem: (key, value) => {
      Cookies.set(`${COOKIE_PREFIX}${key}`, JSON.stringify(value));
    },
    removeItem: (key) => {
      Cookies.remove(`${COOKIE_PREFIX}${key}`);
    },
  },
);

export const useDevValues = () => {
  const [devValues, setDevValues] = useAtom(devValuesAtom);

  const updateValues = useCallback(
    (newValues: Partial<DevValues>) => {
      setDevValues((prev) => ({ ...prev, ...newValues }));
    },
    [setDevValues],
  );

  return {
    values: devValues,
    updateValues,
  };
};
