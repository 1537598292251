import { useDevValues } from '@/hooks/use-dev-values';
import { useKeyboardToggle } from '@/hooks/use-keyboard-toggle';
import { cn } from '@/lib/utils';
import { ChevronRight, RefreshCw } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { InlineMath } from 'react-katex';

const DEFAULT_VALUES = {
  followerWeight: 0.005,
  timeWeight: 0.005,
  timeDecayMin: 0.001,
};

type DebugInputProps = Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> & {
  label: string;
  value: number | undefined;
  onChange: (value: number) => void;
  step?: number;
  defaultValue?: number;
};

const DebugInput = ({
  label,
  value,
  defaultValue,
  onChange,
  step,
  className,
  ...divProps
}: DebugInputProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number.parseFloat(e.target.value);
      if (!Number.isNaN(newValue)) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    if (defaultValue !== undefined) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange]);

  return (
    <div
      className={cn('relative flex flex-col gap-1 group', className)}
      {...divProps}
    >
      <label
        htmlFor={`debug-input-${label}`}
        className="text-xs font-medium text-gray-500 transition-colors group-hover:text-gray-700"
      >
        {label}
      </label>
      <div className="relative">
        <input
          id={`debug-input-${label}`}
          type="number"
          value={value ?? defaultValue}
          onChange={handleChange}
          min="0.000001"
          step={step}
          className="h-7 w-full rounded-md border border-gray-200 bg-white px-2 py-1 text-xs transition-all hover:border-gray-300 focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
        />
        {defaultValue !== undefined && value !== defaultValue && (
          <button
            type="button"
            onClick={handleReset}
            className="absolute right-7 top-1/2 -translate-y-1/2 text-muted-foreground opacity-0 transition-opacity hover:text-primary group-hover:opacity-100"
          >
            <RefreshCw className="h-3.5 w-3.5" />
          </button>
        )}
      </div>
    </div>
  );
};

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

const CollapsibleSection = ({
  title,
  children,
  defaultOpen = false,
}: CollapsibleSectionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="w-full rounded-lg border border-gray-250 transition-all hover:border-gray-500">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full items-center justify-between rounded-md bg-gray-50 px-3 py-1.5 text-xs font-medium transition-colors hover:bg-gray-100"
      >
        <span className="transition-colors group-hover:text-primary">
          {title}
        </span>
        <div
          className="transition-transform duration-200"
          style={{ transform: `rotate(${isOpen ? '90deg' : '0deg'})` }}
        >
          <ChevronRight className="h-4 w-4" />
        </div>
      </button>
      <div
        className="grid transition-[grid-template-rows] duration-200"
        style={{ gridTemplateRows: isOpen ? '1fr' : '0fr' }}
      >
        <div className="overflow-hidden">
          <div className="p-2">{children}</div>
        </div>
      </div>
    </div>
  );
};

interface FactorTableProps {
  data: Array<{ label: string; value: number; normalized?: string }>;
  title: string;
}

const FactorTable = ({ data, title }: FactorTableProps) => {
  return (
    <div className="w-full overflow-hidden rounded-lg border border-gray-200">
      <table className="w-full text-xs">
        <thead>
          <tr className="border-b border-gray-200 bg-gray-50">
            <th className="p-1.5 text-left">
              {title.includes('Follower') ? 'Followers' : 'Age'}
            </th>
            {data[0].normalized && <th className="p-1.5 text-right">Norm.</th>}
            <th className="p-1.5 text-right">Factor</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ label, value, normalized }, index) => (
            <tr
              key={label}
              className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
            >
              <td className="p-1.5">{label}</td>
              {normalized && (
                <td className="p-1.5 text-right font-mono">{normalized}</td>
              )}
              <td className="p-1.5 text-right font-mono">{value.toFixed(4)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FormulaExplanation = ({
  followerWeight,
  timeWeight,
}: {
  followerWeight: number;
  timeWeight: number;
}) => {
  const [followerCount, setFollowerCount] = useState(50000);
  const [postAge, setPostAge] = useState(30);
  const [distance, setDistance] = useState(0.3);

  const normalizedFollowers = followerCount / 1000;
  const normalizedTime = postAge / 2550;

  const followerFactor = Math.max(
    0.001,
    1 - Math.exp(-followerWeight * normalizedFollowers),
  );
  const timeFactor = Math.max(
    DEFAULT_VALUES.timeDecayMin,
    Math.exp(-timeWeight * normalizedTime ** 2),
  );

  const finalScore = (1 - distance / 2) * followerFactor * timeFactor;

  const handleInputChange =
    (setter: (value: number) => void, min: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number.parseFloat(e.target.value);
      if (!Number.isNaN(value) && value >= min) {
        setter(value);
      }
    };

  return (
    <div className="space-y-3 text-sm">
      <div className="grid grid-cols-3 gap-2">
        <label className="group flex flex-col gap-1">
          <span className="text-xs text-muted-foreground transition-colors group-hover:text-primary">
            Followers:
          </span>
          <input
            type="number"
            value={followerCount}
            onChange={handleInputChange(setFollowerCount, 0)}
            className="h-7 w-full rounded-md border border-gray-200 bg-white px-2 py-1 text-xs transition-all hover:border-gray-300 focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
            min="0"
            step="1000"
          />
        </label>
        <label className="group flex flex-col gap-1">
          <span className="text-xs text-muted-foreground transition-colors group-hover:text-primary">
            Age (days):
          </span>
          <input
            type="number"
            value={postAge}
            onChange={handleInputChange(setPostAge, 0)}
            className="h-7 w-full rounded-md border border-gray-200 bg-white px-2 py-1 text-xs transition-all hover:border-gray-300 focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
            min="0"
            step="1"
          />
        </label>
        <label className="group flex flex-col gap-1">
          <span className="text-xs text-gray-500 transition-colors group-hover:text-primary">
            Distance:
          </span>
          <input
            type="number"
            value={distance}
            onChange={handleInputChange(setDistance, 0)}
            className="h-7 w-full rounded-md border border-gray-200 bg-white px-2 py-1 text-xs transition-all hover:border-gray-300 focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
            min="0"
            max="1"
            step="0.01"
          />
        </label>
      </div>

      <div className="grid gap-2 rounded-lg border border-gray-200 bg-gray-50 p-3">
        <div className="grid grid-cols-2 gap-2">
          <div className="rounded-md bg-white p-2 shadow-sm transition-colors hover:bg-gray-50">
            <p className="mb-1 text-xs font-medium">Normalized Values:</p>
            <div className="font-mono text-xs">
              <InlineMath math={`f = ${normalizedFollowers.toFixed(1)}K`} />
              <br />
              <InlineMath math={`t = ${normalizedTime.toFixed(3)}`} />
            </div>
          </div>
          <div className="rounded-md bg-white p-2 shadow-sm transition-colors hover:bg-gray-50">
            <p className="mb-1 text-xs font-medium">Weights:</p>
            <div className="font-mono text-xs">
              <InlineMath math={`w_f = ${followerWeight}`} />
              <br />
              <InlineMath math={`w_t = ${timeWeight}`} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2">
          <div className="flex flex-col justify-between rounded-md bg-white p-2 shadow-sm transition-colors hover:bg-gray-50">
            <p className="font-mono text-xs">
              <InlineMath
                math={`\\text{followerFactor} = \\max(0.001, 1 - e^{${(-followerWeight * normalizedFollowers).toFixed(3)}})`}
              />
              <br />
              <InlineMath math={`= ${followerFactor.toFixed(4)}`} />
            </p>
          </div>
          <div className="flex flex-col justify-between rounded-md bg-white p-2 shadow-sm transition-colors hover:bg-gray-50">
            <p className="font-mono text-xs">
              <InlineMath
                math={`\\text{timeFactor} = \\max(${DEFAULT_VALUES.timeDecayMin}, e^{-${timeWeight} \\cdot (${normalizedTime.toFixed(3)})^2})`}
              />
              <br />
              <InlineMath math={`= ${timeFactor.toFixed(4)}`} />
            </p>
          </div>
        </div>

        <div className="rounded-md bg-white p-2 shadow-sm transition-colors hover:bg-gray-50">
          <p className="font-mono text-sm">
            <InlineMath
              math={`\\text{finalScore} = (1 - \\frac{${distance.toFixed(4)}}{2}) \\times ${followerFactor.toFixed(4)} \\times ${timeFactor.toFixed(4)}`}
            />
            <br />
            <InlineMath math={`= ${finalScore.toFixed(4)}`} />
          </p>
        </div>
      </div>
    </div>
  );
};

export const DebugContainer = () => {
  const [isVisible] = useKeyboardToggle({
    keys: ['ctrl', 'shift', 'd'],
  });
  const { values, updateValues } = useDevValues();

  const followerFactorData = useMemo(() => {
    const weight = values.followerWeight ?? DEFAULT_VALUES.followerWeight;
    const followers = [1000, 5000, 10000, 25000, 50000, 80000, 100000, 200000];

    return followers.map((count) => {
      const normalizedCount = count / 1000;
      return {
        label: count >= 1000000 ? `${count / 1000000}M` : `${count / 1000}K`,
        normalized: normalizedCount.toFixed(1),
        value: Math.max(0.001, 1 - Math.exp(-weight * normalizedCount)),
      };
    });
  }, [values.followerWeight]);

  const timeDecayData = useMemo(() => {
    const weight = values.timeWeight ?? DEFAULT_VALUES.timeWeight;
    const times = [
      { label: '1d', days: 1 },
      { label: '7d', days: 7 },
      { label: '30d', days: 30 },
      { label: '90d', days: 90 },
      { label: '180d', days: 180 },
      { label: '365d', days: 365 },
      { label: '2y', days: 365 * 2 },
      { label: '3y', days: 365 * 3 },
    ];

    return times.map(({ label, days }) => {
      const normalizedTime = days / 2550;
      return {
        label,
        normalized: normalizedTime.toFixed(3),
        value: Math.max(
          DEFAULT_VALUES.timeDecayMin,
          Math.exp(-weight * normalizedTime ** 2),
        ),
      };
    });
  }, [values.timeWeight]);

  return (
    <div
      className={cn(
        'fixed right-0 top-0 h-svh w-96 overflow-y-auto border-l border-gray-200 bg-white p-3 shadow-lg text-gray-800 transition-transform duration-300',
        isVisible ? 'translate-x-0' : 'translate-x-full',
      )}
    >
      <style>{`
          @keyframes slideIn {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(0);
            }
          }

          input[type=number]::-webkit-inner-spin-button,
          input[type=number]::-webkit-outer-spin-button {
            opacity: 0;
            transition: opacity 0.2s;
          }

          input[type=number]:hover::-webkit-inner-spin-button,
          input[type=number]:hover::-webkit-outer-spin-button {
            opacity: 1;
          }
        `}</style>

      <div className="flex flex-col gap-3">
        <h2 className="text-lg font-semibold">Debug Panel</h2>

        <div className="space-y-3">
          <DebugInput
            label="Follower Factor Weight"
            defaultValue={DEFAULT_VALUES.followerWeight}
            value={values.followerWeight}
            onChange={(value) => updateValues({ followerWeight: value })}
            step={0.0001}
          />
          <CollapsibleSection title="Follower Factor Results">
            <FactorTable
              data={followerFactorData}
              title="Follower Factor Results"
            />
          </CollapsibleSection>
        </div>

        <div className="space-y-3">
          <div className="flex gap-3">
            <DebugInput
              label="Time Decay Weight"
              defaultValue={DEFAULT_VALUES.timeWeight}
              value={values.timeWeight}
              onChange={(value) => updateValues({ timeWeight: value })}
              step={0.0001}
              className="flex-1"
            />
            <DebugInput
              label="Time Decay Min"
              defaultValue={DEFAULT_VALUES.timeDecayMin}
              value={values.timeDecayMin}
              onChange={(value) => updateValues({ timeDecayMin: value })}
              step={0.0001}
              className="flex-4"
            />
          </div>
          <CollapsibleSection title="Time Decay Results">
            <FactorTable data={timeDecayData} title="Time Decay Results" />
          </CollapsibleSection>
        </div>

        <div className="border-t border-gray-200 pt-3">
          <FormulaExplanation
            followerWeight={
              values.followerWeight ?? DEFAULT_VALUES.followerWeight
            }
            timeWeight={values.timeWeight ?? DEFAULT_VALUES.timeWeight}
          />
        </div>
      </div>
    </div>
  );
};
