import { Button } from '@/components/ui/button';
import { Link } from '@tanstack/react-router';

export interface NotFoundPageProps {
  title?: string;
  description?: string;
  showHomeButton?: boolean;
}

export const NotFoundPage = ({
  title = '404',
  description = '존재하지 않는 페이지입니다.',
  showHomeButton = true,
}: NotFoundPageProps) => {
  return (
    <main className="flex min-h-svh flex-col items-center justify-center">
      <h1 className="font-bold text-6xl text-gray-800">{title}</h1>
      <p className="mt-4 text-2xl text-gray-600">{description}</p>
      {showHomeButton && (
        <Link to="/" className="mt-6">
          <Button variant="secondary">홈으로 가기</Button>
        </Link>
      )}
    </main>
  );
};
