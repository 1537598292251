import { OverlayProvider } from 'overlay-kit';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { DevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
import 'katex/dist/katex.min.css';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  QueryClient,
  QueryClientProvider,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Provider as JotaiProvider } from 'jotai/react';
import { Fragment, Suspense, useMemo } from 'react';
import { ampli } from './ampli';
import { ThemeProvider } from './components/theme-provider';
import { routeTree } from './routeTree.gen';
import { jotaiStore } from './store/jotai';
import { apolloClient } from './utils/apollo';
import { initAuth, useAuthContext } from './utils/auth';

const queryClient = new QueryClient();

if (import.meta.env.PROD) {
  ampli.load({
    environment: 'bzinecreatorclip',
    disabled: import.meta.env.DEV,
  });

  Sentry.init({
    dsn: 'https://e4ea65a5e7bd9fe3296f40748b561dca@internal-sentry.bzine.co/9',
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 0.7,
    tracesSampleRate: 0.7,
    normalizeDepth: 6,
    attachStacktrace: true,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/apis\.brandazine\.kr/,
      /^https:\/\/apis-dev\.brandazine\.com/,
      /^https:\/\/apis-next\.brandazine\.com/,
      /^https:\/\/apis-next\.bzine\.co/,
    ],
  });
}

const router = createRouter({
  routeTree,
  context: {
    queryClient,
    apolloClient,
    auth: {
      accessToken: null,
      isAuthenticated: false,
      role: null,
    },
  },
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 60_000,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const { data } = useSuspenseQuery({
    queryKey: ['auth'],
    queryFn: initAuth,
  });

  const auth = useAuthContext();

  const $auth = useMemo(() => {
    // @see 간헐적으로 jotai가 sync가 안됐을 경우를 가정
    if (data !== auth.accessToken) {
      return {
        accessToken: data,
        isAuthenticated: data !== null,
        role: auth.role,
      };
    }
    return {
      accessToken: auth.accessToken,
      isAuthenticated: auth.isAuthenticated,
      role: auth.role,
    };
  }, [data, auth]);

  return (
    <RouterProvider
      router={router}
      context={{
        auth: $auth,
      }}
    />
  );
}

function App() {
  return (
    <ThemeProvider defaultTheme="dark">
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
          <JotaiProvider store={jotaiStore}>
            <OverlayProvider>
              {import.meta.env.DEV ? (
                <Fragment>
                  <DevTools store={jotaiStore} position="bottom-right" />
                  <ReactQueryDevtools buttonPosition="top-right" />
                </Fragment>
              ) : null}
              <Suspense>
                <InnerApp />
              </Suspense>
            </OverlayProvider>
          </JotaiProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
