import { ampli } from '@/ampli';
import { graphql } from '@/gql';
import { jotaiStore } from '@/store/jotai';
import { accessTokenAtom, getUserAccountIdFromCookie } from '@/utils/auth';
import { loadAbility } from '@/utils/casl';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { useSetAtom } from 'jotai';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import * as v from 'valibot';

const querySchema = v.object({
  redirect: v.optional(v.string(), '/'),
});

type Query = v.InferInput<typeof querySchema>;

export const Route = createFileRoute('/auth/login')({
  component: LoginPage,
  validateSearch: (search) => v.parse(querySchema, search),
  loaderDeps: ({ search }) => {
    return search;
  },
  beforeLoad: ({ context, location }) => {
    if (context.auth.isAuthenticated) {
      const redirectTo = (location.search as Query).redirect || '/';
      return redirect({
        to: redirectTo,
      });
    }
    return context;
  },
});

const LOGIN_MUTATION = graphql(/* GraphQL */ `
  mutation Login($input: AuthenticateInput!) {
    authenticateV2(input: $input) {
      accessToken
    }
  }
`);

function LoginPage() {
  const navigate = useNavigate();
  const { redirect } = Route.useSearch();
  const setToken = useSetAtom(accessTokenAtom);

  const [login] = useMutation(LOGIN_MUTATION, {
    onCompleted: async (data) => {
      jotaiStore.set(accessTokenAtom, data.authenticateV2.accessToken);

      const ability = await loadAbility();
      if (!ability?.can('read', 'CLIP')) {
        console.error('No permission');
        Cookies.remove('ua_id');
        Cookies.remove('ua_refresh');
        return;
      }

      const userAccountId = getUserAccountIdFromCookie();
      ampli.identify(userAccountId);
      Sentry.setUser({
        id: userAccountId,
      });
      setToken(data.authenticateV2.accessToken);
      navigate({ to: redirect || '/' });
    },
    onError: (error) => {
      console.error('Login error:', error);
    },
  });

  useEffect(() => {
    const attemptGuestLogin = async () => {
      login({
        variables: {
          input: {
            type: 'GUEST',
            token: '',
          },
        },
      });
    };

    void attemptGuestLogin();
  }, []);

  return (
    <main className="h-svh w-full overflow-hidden bg-[#1A1B1C] flex items-center justify-center">
      <div className="text-white">Loading...</div>
    </main>
  );
}
