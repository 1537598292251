export const dynamicChunk = <T>(
  array: readonly T[] | T[],
  sizeFn: (index: number) => number,
) => {
  const result: T[][] = [];
  const length = array.length;
  let i = 0;

  while (i < length) {
    const size = sizeFn(result.length);

    // Use mutable push to avoid creating intermediate arrays
    const chunk: T[] = new Array(size);
    const end = Math.min(i + size, length);
    for (let j = i; j < end; j++) {
      chunk[j - i] = array[j];
    }
    result.push(chunk);
    i += size;
  }

  return result;
};
