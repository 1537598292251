import { ampli } from '@/ampli';
import { graphql } from '@/gql';
import { jotaiStore } from '@/store/jotai';
import { apolloClient } from '@/utils/apollo';
import * as Sentry from '@sentry/react';
import { atom, useAtomValue } from 'jotai';
import Cookies from 'js-cookie';
import * as v from 'valibot';

const JwtSchema = v.object({
  actor: v.object({
    id: v.string(),
    propertyId: v.string(),
    type: v.string(),
  }),
  exp: v.number(),
  iat: v.number(),
  role: v.string(),
  sub: v.string(),
  userAccountId: v.string(),
  ver: v.number(),
});

export interface AuthContext {
  accessToken: string | null;
  isAuthenticated: boolean;
  role: string | null;
}

export const accessTokenAtom = atom<string | null>(null);
accessTokenAtom.debugLabel = 'accessTokenAtom';

export const isAuthenticatedAtom = atom<boolean>((get) =>
  Boolean(get(accessTokenAtom)),
);
isAuthenticatedAtom.debugLabel = 'isAuthenticatedAtom';

export const roleAtom = atom<string | null>((get) => {
  const accessToken = get(accessTokenAtom);
  if (!accessToken) {
    return null;
  }

  try {
    const payload = extractAccessTokenPayload(accessToken);
    return payload.role || null;
  } catch {
    return null;
  }
});
roleAtom.debugLabel = 'roleAtom';

export const initAuth = async () => {
  const userAccountId = getUserAccountIdFromCookie();
  ampli.identify(userAccountId);
  Sentry.setUser({
    id: userAccountId,
  });

  if (typeof userAccountId !== 'string') {
    return null;
  }

  try {
    const { data } = await apolloClient.mutate({
      mutation: AUTHENTICATE_MUTATION,
      variables: {
        input: {
          type: 'REFRESH_TOKEN_BY_COOKIE',
        },
      },
    });

    const accessToken = data?.authenticateV2?.accessToken ?? null;

    if (!accessToken) {
      return null;
    }

    jotaiStore.set(accessTokenAtom, accessToken);
    return accessToken;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const useAuthContext = (): AuthContext => {
  const accessToken = useAtomValue(accessTokenAtom);
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const role = useAtomValue(roleAtom);

  return { accessToken, isAuthenticated, role };
};

export const useRole = () => useAtomValue(roleAtom);

export const useIsStaff = () => {
  const role = useRole();
  return role === 'STAFF';
};

export const AUTHENTICATE_MUTATION = graphql(/* GraphQL */ `
  mutation Authenticate($input: AuthenticateInput!) {
    authenticateV2(input: $input) {
      accessToken
    }
  }
`);

export const signIn = async (token: string) =>
  apolloClient.mutate({
    mutation: AUTHENTICATE_MUTATION,
    variables: {
      input: {
        type: 'IDENTITY_TRANSFER_TOKEN',
        token,
      },
    },
  });

export const refreshToken = () =>
  apolloClient.mutate({
    mutation: AUTHENTICATE_MUTATION,
    variables: {
      input: {
        type: 'REFRESH_TOKEN_BY_COOKIE',
      },
    },
  });

export const checkPossiblyAuthenticated = () =>
  typeof getUserAccountIdFromCookie() === 'string';

export const getUserAccountIdFromCookie = () => Cookies.get('ua_id');

export const clearAuthState = () => {
  Cookies.remove('ua_id');
  jotaiStore.set(accessTokenAtom, null);
};

export const extractAccessTokenPayload = (accessToken: string) => {
  const [, payload] = accessToken.split('.');
  return v.parse(JwtSchema, JSON.parse(atob(payload)));
};

export const extractAccessTokenExpiresAt = (accessToken: string) => {
  const payload = extractAccessTokenPayload(accessToken);

  if ('exp' in payload && typeof payload.exp === 'number') {
    return payload.exp as number;
  }
  return null;
};

export const isTokenExpired = (token: string) => {
  const expirationTime = extractAccessTokenExpiresAt(token);
  if (!expirationTime) {
    return true;
  }

  return Date.now() / 1000 > expirationTime;
};
