import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const inputVariants = cva('flex h-10 w-full px-3 py-1 text-sm', {
  variants: {
    variant: {
      default:
        'rounded-lg bg-[rgba(255,255,255,0.10)] text-[13px] outline-none placeholder:text-gray-500',
      outline:
        'rounded-full bg-transparent shadow-sm transition-colors file:border-0 file:bg-transparent file:font-medium file:text-foreground file:text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

const InputError = ({ message }: { message?: string }) => {
  if (!message) {
    return null;
  }
  return <p className="mt-1 text-[#E82604] text-[12px]">{message}</p>;
};

export { Input, InputError };
