import { registerAmplitudeMap } from '@/ampli/event-schema';
import {
  GridClipImageContainer,
  GridClipImageContainerFallback,
} from '@/components/grid-clip-image-container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { graphql } from '@/gql';
import { useDevValues } from '@/hooks/use-dev-values';
import { NetworkStatus, useSuspenseQuery } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import {
  Link,
  createFileRoute,
  redirect,
  useRouter,
} from '@tanstack/react-router';
import { debounce } from 'es-toolkit';
import {
  Suspense,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdArrowBackIosNew, MdSearch } from 'react-icons/md';
import { useDebounce } from 'use-debounce';
import * as v from 'valibot';
import { ImagePage } from './images/$clipImageId';

const querySchema = v.object({
  q: v.optional(v.string(), ''),
});

registerAmplitudeMap.set('/search', {
  schema: querySchema,
  eventName: 'VIEW_SEARCH_PAGE',
});

export const Route = createFileRoute('/search')({
  component: SearchPage,
  validateSearch: (search) => v.parse(querySchema, search),
  loaderDeps: ({ search }) => {
    return search;
  },
  beforeLoad: ({ search }) => {
    if (!search.q) {
      throw redirect({
        to: '/search-preview',
        replace: true,
      });
    }
  },
  staleTime: Number.POSITIVE_INFINITY,
});

const SEARCH_QUERY = graphql(
  /* GraphQL */ `
  query SearchQuery($query: String, $limit: Int!, $offset: Int!, $searchQueryId: UUID, $params: SearchClipImagesParamsDto) {
    clipImages(query: $query, limit: $limit, offset: $offset, searchQueryId: $searchQueryId, params: $params) @connection(key: "clipImages") {
      searchQueryId
      items {
        id
        smallImageUrl
        ...ClipImageFragment

        # preload
        ...DetailProfileFragment
      }
    }
  }
`,
  [GridClipImageContainer.fragments, ImagePage.fragments],
);

const SearchResult = ({ query }: { query: string }) => {
  const { values: devValues } = useDevValues();
  const params = useMemo(
    () => ({
      followerWeight: devValues.followerWeight,
      timeWeight: devValues.timeWeight,
      timeDecayMin: devValues.timeDecayMin,
    }),
    [devValues],
  );

  const { data, error, fetchMore, networkStatus } = useSuspenseQuery(
    SEARCH_QUERY,
    {
      variables: {
        query,
        limit: 24,
        offset: 0,
        params,
      },
    },
  );

  const [isFetching, setIsFetching] = useState(false);
  const hasMoreRef = useRef(true);
  const fetchingRef = useRef(false);

  const handleFetchMore = useCallback(() => {
    if (
      networkStatus === NetworkStatus.ready &&
      !fetchingRef.current &&
      hasMoreRef.current &&
      (data?.clipImages?.items?.length ?? 0) > 0
    ) {
      fetchingRef.current = true;
      setIsFetching(true);

      startTransition(() => {
        fetchMore({
          variables: {
            query,
            searchQueryId: data.clipImages.searchQueryId,
            offset: data.clipImages.items?.length ?? 0,
            limit: 12,
            params,
          },
        })
          .then((result) => {
            if (
              !result.data.clipImages.items ||
              result.data.clipImages.items.length === 0
            ) {
              hasMoreRef.current = false;
            }
          })
          .catch(console.error)
          .finally(() => {
            fetchingRef.current = false;
            setIsFetching(false);
          });
      });
    }
  }, [networkStatus, data, fetchMore, query, params]);

  const debouncedFetchMore = useMemo(
    () => debounce(handleFetchMore, 200),
    [handleFetchMore],
  );

  useEffect(() => {
    return () => {
      debouncedFetchMore.cancel();
    };
  }, [debouncedFetchMore]);

  if (error) {
    throw error;
  }

  const items = data?.clipImages?.items ?? [];

  return (
    <GridClipImageContainer
      $data={items}
      fetching={isFetching}
      searchQueryId={data.clipImages.searchQueryId}
      onEndReached={debouncedFetchMore}
      hasMore={hasMoreRef.current}
    />
  );
};

function SearchPage() {
  const query = Route.useSearch({ select: (s) => s.q });
  const [deferredQuery] = useDebounce(query, 200);

  const navigate = Route.useNavigate();
  const { history } = useRouter();

  return (
    <div className="flex h-svh w-full flex-col overflow-hidden">
      <div className="flex flex-row items-center gap-[9px] px-4 pt-[13px] pb-5">
        <button type="button" onClick={() => history.back()}>
          <MdArrowBackIosNew size={24} />
        </button>
        <div
          className="relative w-full"
          style={{
            viewTransitionName: 'search-input',
          }}
        >
          <MdSearch
            className="absolute top-0 left-0 mx-3 h-10 w-4 text-muted-foreground"
            color="#fff"
          />
          <Input
            autoFocus
            variant="outline"
            className="bg-white/10 pl-9 outline-none"
            defaultValue={query}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value) {
                navigate({ search: { q: value }, replace: true });
              }
            }}
          />
        </div>
      </div>

      <ErrorBoundary
        fallback={
          <div className="flex flex-1 flex-col items-center justify-center px-7">
            <p className="text-center font-semibold text-base text-white">
              다른 검색어를 입력해 주세요
            </p>
            <p className="mt-3 whitespace-pre text-center font-normal text-muted-foreground text-sm text-white">
              {
                '검색 결과를 찾을 수 없는 검색어입니다.\n새로운 검색어로 다시 검색해 주세요.'
              }
            </p>

            <Link to="/search-preview" replace>
              <Button variant="secondary" size="md" className="mt-8 w-full">
                다시 검색하기
              </Button>
            </Link>
          </div>
        }
      >
        <Suspense fallback={<GridClipImageContainerFallback />}>
          <SearchResult query={deferredQuery} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
